import { isClient } from '@qcwp/utils'
import { getArticleRecommend } from '~~/src/server/modules/article'
import type { ArticleRecommendParams, ArticleRecommendResponse } from '~~/src/server/modules/article/type'
/**
 * 查询推荐位文章列表
 */
export function LogicRecommend() {
  const list = ref<ArticleRecommendResponse[]>([])

  async function clientInitHandler(params: ArticleRecommendParams) {
    if (!isClient)
      return
    const { data } = await fetchErrorCollect(() => getArticleRecommend(params)) || {}
    if (data && data.list)
      list.value = data.list
  }
  async function serverInitHandler(params: ArticleRecommendParams) {
    const asyncData = await useAsyncData(`RECOMMEND_ARTICLE_${qs.stringify(params)}`, () => getArticleRecommend(params))
    const { data } = useNuxtAsyncHandle(asyncData) || {}
    if (data && data.list)
      list.value = data.list
  }

  return {
    list,
    clientInitHandler,
    serverInitHandler,
  }
}
